.spacer {
  height: 20px;
  margin: 5px 10px;
}

.giveMinWidth {
  min-width: 500px;
  max-width: 900px;
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
}

.actionButtonContainer {
  width: 80%;
  margin-top: 22px;
}

.hidden {
  position: fixed;
  z-index: -100;
  top: 0;
}

@media screen and (max-width: 1200px) {
  .giveMinWidth {
    margin-bottom: 10px;
    min-width: 400px;
    padding: 10px;
    max-width: 90vw;
  }
}

@media screen and (max-width: 500px) {
  .giveMinWidth {
    margin-bottom: 10px;
    min-width: 340px;
    width: 90%;
    padding: 10px;
  }
}
